import { useEffect, useState } from 'preact/hooks';
import { createPortal } from 'react-dom';
import { useGameClientProxy } from '../../../../Launcher/GameClient/hooks/useGameClientProxy';
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../../../../../redux/types';
import { settleJackpotWin } from '../../../../../redux/reducers/site-wide-jackpot/site-wide-jackpot';
import { Fragment } from 'preact';
import { datadogLogs } from '@datadog/browser-logs';
require(`./styles/${process.env.APPLICATION}/style.scss`);

export const JackpotWinScreen = ({ wonJackpot }) => {
    const [winScreenNumber, setWinScreenNumber] = useState(0);
    const isSpinInProgress = useSelector(state => state?.gameWindow?.spinInProgress);
    const dispatch = useDispatch();
    //text will be from prismic later, placeholders are defined here and will be replaced
    const firstWinMessage = 'You\'ve won a jackpot';
    const jackpotWinMessage = 'You won a';
    const winAvailabilityMessage = 'Win available in your wallet now!';
    const winSmallMessage = '*Subject to federal and state withholding requirements';

    useEffect(() => {
        if (winScreenNumber > 0 && winScreenNumber < 3 ) {
            setTimeout(() => {
                setWinScreenNumber((prev) => prev + 1);
            }, 3000)
        }

    }, [winScreenNumber]);

    useEffect(() => {
        if (wonJackpot) {
            autoSpinStop();
            // reloadBalance();
        }
        setWinScreenNumber(0);
    }, [wonJackpot]);

    useEffect(() => {
        datadogLogs.logger.info('[GW][SWJ]jackpots_win_dialog_did_appear', {});
    })

    const {
        autoSpinStop,
        autoSpinResume,
        reloadBalance
    } = useGameClientProxy();

    const handleRevealButton = () => {
        setWinScreenNumber((prev) => prev + 1);
    };

    const handleBackToGameButton = () => {
        datadogLogs.logger.info('[GW][SWJ]jackpots_win_dialog_did_disappear', {});
        dispatch({
            type: types.SET_SWJ_JACKPOT_DATA,
            payload: {}
        });
        reloadBalance();
        dispatch(settleJackpotWin());
        autoSpinResume();
    }

    const wonJackpotTypeComponent = () => {
        return (
            <Fragment>
                <div className="win-first-text">{jackpotWinMessage}</div>
                <div className="win-second-text">{wonJackpot.name.toUpperCase()}</div>
            </Fragment>
        );
    }

    const winInfoTexts = [
        <div key="0" className="win-message-0">{firstWinMessage}</div>,
        <div key="1" className="win-message-1">{wonJackpot.name.toUpperCase()}</div>,
        <div key="2" className="win-message-2">{wonJackpotTypeComponent()}</div>,
        <div key="3" className="win-message-3">
            {wonJackpotTypeComponent()}
            <div className="win-value">{`$${wonJackpot.amount}`}</div>
            <div className="win-info">{winAvailabilityMessage}</div>
            <div className="win-info-small">{winSmallMessage}</div>
        </div>

        ];

    if (isSpinInProgress) {
        return null;
    }

    return (
        createPortal(
            <div className="jackpot-win-screen-wrapper">
                <img className="static-wheel" src="/assets/icons/SWJ/static-wheel.png" />
                <div className={`win-screen-gradient${winScreenNumber === 3 ? '-large' : ''}`} />
                <div className="win-chyron-wrapper">
                    <div className="win-chyron" />
                </div>
                {winInfoTexts[winScreenNumber]}
                <div className="button-wrapper">
                    {
                       winScreenNumber === 0 ?
                       <button className="win-button" onClick={handleRevealButton}> Reveal </button> :
                        winScreenNumber === 3 && <button className="win-button" onClick={handleBackToGameButton}> Back to game </button>
                    }

                </div>
            </div>
        , document.body)

    );
};
